.App {
  text-align: center;
}

body {
  background: linear-gradient(to right, #a0a5f1, #99eed4);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* Styles for webkit browsers */
.custom-scrollbar::-webkit-scrollbar {
  width: 10px; /* width of the entire scrollbar */
  opacity: 0; /* scrollbar is invisible by default */
  transition: opacity 0.2s; /* smooth transition for opacity changes */
}

.custom-scrollbar:hover::-webkit-scrollbar {
  opacity: 1; /* scrollbar becomes visible on hover */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 20px;
  border: 3px solid #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Styles for Firefox */
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: transparent #f1f1f1; /* scrollbar thumb is transparent by default */
}

.custom-scrollbar:hover {
  scrollbar-color: #888 #f1f1f1; /* scrollbar thumb appears on hover */
}

@media (max-width: 768px) {
  .sidebar {
    display: none; /* Hide sidebar by default on mobile */
    white-space: nowrap; /* Keep text in a single line */
    overflow: hidden; /* Hide overflow */
    text-overflow: ellipsis; /* Use ellipsis for overflow text */
    width: 550px;
  }
  .sidebar-visible {
    display: block; /* Show sidebar when toggled */
    white-space: nowrap; /* Keep text in a single line */
    overflow: hidden; /* Hide overflow */
    text-overflow: ellipsis; /* Use ellipsis for overflow text */
    width: 550px;
  }
}
